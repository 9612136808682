<template>
  <!-- 右侧固定标签 -->
  <div class="icons">
    <div :style="{ background: floatIconColor }" @click="onWechatClick" :class="{ active: wechatPopState }" class="item">
      <img class="w-icon" src="~/assets/img/vector-wechat.png" label="wechat" />
      <div v-if="wechatPopState" class="qr-wrap">
        <img class="qr" :src="right.img_sl" />
        <span>{{ right.wx_title }}</span>
        <div @click.stop="onWechatClick" class="close">
          <i class="i-heroicons-x-mark-20-solid"></i>
        </div>
      </div>
    </div>

    <div :style="{ background: floatIconColor }" @click="onPhoneClick" :class="{ active: phonePopState }" class="item">
      <img src="~/assets/img/vector-phone.png" label="phone" />
      <div v-if="phonePopState" class="phone">
        <span>{{ right.phone }}</span>
        <div @click.stop="onPhoneClick" class="close">
          <i class="i-heroicons-x-mark-20-solid"></i>
        </div>
      </div>
    </div>
    <NuxtLink :to="kefu.link_url" target="_blank" class="item" :style="{ background: floatIconColor }">
      <img src="~/assets/img/vector-message.png" />
    </NuxtLink>
    <img class="back" @click="scrollToTop" src="~/assets/img/icon-float-up.png" />
  </div>
</template>

<script lang="ts" setup>
const store = useSiteStore()
let { right, kefu, floatIconColor } = storeToRefs(store)
let wechatPopState = ref(false)
let phonePopState = ref(false)
const onWechatClick = () => {
  wechatPopState.value = !wechatPopState.value
  phonePopState.value = false
}
const onPhoneClick = () => {
  phonePopState.value = !phonePopState.value
  wechatPopState.value = false
}
function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>

<style lang="scss" scoped>
.icons {
  position: fixed;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 49;
  bottom: 100px;

  .item {
    position: relative;
    width: 54px;
    height: 90px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary-color;
    border-radius: 100px 100px 100px 100px;
    img {
      height: auto;
      width: 32px;
    }
    .w-icon {
      width: 30px;
    }
  }
  .active {
    background-color: #3e3f3f !important;
  }
  .phone {
    font-size: 16px;
    font-weight: 600;
    color: #161d25;
    padding: 14px 40px 14px 20px;
    min-width: 260px;
    position: absolute;
    right: calc(100% + 10px);
    top: 20px;
    background-color: #fff;
    border-radius: 6px;
  }
  .back {
    margin-bottom: 20px;
    cursor: pointer;
    width: 54px;
    height: 90px;
  }
  .qr-wrap {
    position: absolute;
    right: calc(100% + 10px);
    top: -32px;
    width: 139px;
    height: 160px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(19, 147, 255, 0.2);
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    display: flex;
    padding: 14px;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    .qr {
      width: 120px;
      height: 120px;
    }
    .span {
      font-size: 13px;
      text-align: center;
      font-weight: 400;
      color: #959ead;
    }
  }

  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    left: -10px;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    i {
      width: 15px;
      color: #fff;
      height: 15px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .icons {
    right: 20px;
  }
}

@media (max-width: 768px) {
  .icons {
    right: 10rpx;
    bottom: 20rpx;
    .item {
      width: 80rpx;
      height: 133rpx;
      margin-bottom: 20rpx;
      img {
        width: 39rpx;
        height: 33rpx;
      }
      .w-icon {
        width: 40rpx;
        // height: 34rpx;
        height: auto;
      }

      .phone {
        width: 34rpx;
        height: 80rpx;
        line-height: 1;
        span {
          line-height: 1;
        }
      }
    }
    .back {
      width: 80rpx;
      height: 133rpx;
      margin-bottom: 20rpx;
    }
  }
}
</style>
<style lang="scss">
.icons {
  .ring-1 {
    overflow: visible;
  }
}
</style>
